import { useEffect, useState } from "react";
import SpinViewer from "./views/SpinViewer";
import Tabs from "./shared/components/Tabs";
import "./styles/style.scss";
import { HelloARManager } from "helloar/build/helloar.min.js";
import { ProductData } from "./shared/helper/productData";
import Gallery from "./views/Gallery";
import { IApp } from "./shared/models/App";
import SphericalViewer from "./views/SpherialViewer";
import { MappingData } from "./shared/helper/mappingData";
import { IMapping } from "./shared/models/Mapping";
import Audio from "./views/Audio";
import { findSubscriptionStatus } from "./utils";

function App() {
  const [selectedTab, setSelectedTab] = useState("");
  const params = new URLSearchParams(window.location.search);
  const productId = params.get("id"); //?id=6193acbd646929993f0dc8db
  const [product, setProduct] = useState<ProductData>();
  const [appData, setAppData] = useState<IApp>();
  const [mapping, setMapping] = useState<MappingData>();
  const [audioPresent, setAudioPresent] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);

  //fetch data
  useEffect(() => {
    if (productId) {
      HelloARManager.getInstance().useProdServer();
      HelloARManager.getInstance().dataStore.loadData({
        productId: productId,
        success: success,
        failure: failure,
      });
    }
  }, [productId]);

  //set default selected tab
  useEffect(() => {
    if (mapping && product) {
      let tabs = mapping?.getTabs();
      setSelectedTab(tabs[0]);
    }
  }, [mapping, product]);

  const success = () => {
    //get product data
    const data = HelloARManager.getInstance().dataStore.getProduct();
    let newProduct = new ProductData(data);
    setProduct(newProduct);

    //get app data
    const appData = HelloARManager.getInstance().dataStore.getApplication();
    setIsSubscriptionActive(findSubscriptionStatus(appData));
    setAppData(appData);

    //get mapping data
    const mapData: string = appData.sdk_settings.viewer_capture_mapping;

    let newMapData: IMapping[] = JSON.parse(mapData);

    //filter with tabs which has data
    newMapData = newMapData.filter((item) => {
      switch (item.type) {
        case "Spin":
          return newProduct.getSpinData(item.slot_names)?.image_folder_url
            ? newProduct.getSpinData(item.slot_names)?.image_folder_url
            : newProduct.getSmartSpinData();
        case "Sperical":
          return newProduct.getSphericalData(item.slot_names)?.image_url
            ? newProduct.getSphericalData(item.slot_names)?.image_url
            : null;
        case "Multiple":
          return newProduct.getGalleryData()
            ? newProduct.getGalleryData()
            : null;
        default:
          return newProduct.getSpinData(item.slot_names)?.image_folder_url
            ? newProduct.getSpinData(item.slot_names)?.image_folder_url
            : null;
      }
    });

    setMapping(new MappingData(newMapData));
  };

  const failure = () => {
    setFailed(true);
    console.log("---failure---");
  };

  const TabContainer = () => {
    if (mapping && product) {
      let selectedMapping = mapping.getTabData(selectedTab);
      if (selectedMapping) {
        switch (selectedMapping.type) {
          case "Spin":
            return (
              <SpinViewer
                imageSmart={product.getSmartSpinData()}
                image={product.getSpinData(selectedMapping.slot_names)}
                viewerImageEndBug={appData?.sdk_settings.viewer_end_image_bug}
                autoPlay={appData?.sdk_settings.auto_play_spin}
                _id={product.getId()}
              />
            ); //exterior
          case "Sperical":
            return (
              <SphericalViewer
                image={product.getSphericalData(selectedMapping.slot_names)}
              />
            );
          case "Multiple":
            return (
              <Gallery
                subTabs={product.getSubTabs(selectedMapping.sub_tabs)}
                slotImages={product.getGalleryData()}
                videos={product.getVideoData()}
                audios={product.getAudioData()}
              />
            );
          case "Audio":
            setAudioPresent(true);
            return <></>;
          default:
            return (
              <SpinViewer
                imageSmart={product.getSmartSpinData()}
                image={product.getSpinData(selectedMapping.slot_names)}
                viewerImageEndBug={appData?.sdk_settings.viewer_end_image_bug}
                autoPlay={appData?.sdk_settings.auto_play_spin}
                _id={product.getId()}
              />
            );
        }
      } else {
        return (
          <div className="error">
            <h6>loading...</h6>
          </div>
        );
      }
    } else if (failed) {
      return (
        <div className="error">
          <h6>Data not found</h6>
        </div>
      );
    } else {
      return <div className="error"></div>;
    }
  };

  const setTabSelected = (name: string) => setSelectedTab(name);

  if (!isSubscriptionActive) {
    return (
      <div className="error-screen">
        <div className="error-box">
          Your HelloAR Subscription has expired.
          <br />
          Please contact support@helloar.io to renew your Subscription.
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {mapping && product ? (
            <Tabs
              tabs={mapping.getTabs()}
              setTabSelected={setTabSelected}
              selectedTab={selectedTab}
            />
          ) : (
            ""
          )}
        </div>
        <div style={{ position: "relative" }}>
          {audioPresent && product ? (
            <Audio audios={product?.getAudioData()} />
          ) : (
            ""
          )}
        </div>
      </div>
      <TabContainer />
    </div>
  );
}

export default App;
