import { IHotspot } from "../models/Hotspot";
import close from "../../assets/icon_close.png"

type Props = {
    data: IHotspot;
    hotSpotDisplayAction: () => void;
}

export default function HotspotUI(props: Props) {

    const handleClose = () => { props.hotSpotDisplayAction() }

    return <div className="hotspotContainer">
        <img className="close" alt="" src={close} onClick={handleClose} />
        <div className="img">
            <img alt="" src={props.data.image_url} />
        </div>
        <h6 className="title">{props.data.description}</h6>
        {/* <a href="/">View Damages in Gallery</a> */}
    </div>
};
