import img360 from "../../assets/icon_360.svg"
import zoomIn from "../../assets/icon_zoom_in.svg"
import zoomOut from "../../assets/icon_zoom_out.svg"
// import fullscreen from "../../assets/icon_full_screen.svg"
// import exitFullscreen from "../../assets/icon_exit_fullscreen.png"

type Props = {
    onFullScreen: () => void
    onZoomIn: () => void
    onZoomOut: () => void
    isFullScreen: boolean
    scale: number;
    hideZoom: boolean;
}

export default function Controls(props: Props) {

    // const handleFullScreen = () => {
    //     props.onFullScreen();
    // }

    const handleZoomOut = () => {
        props.onZoomOut();
    }

    const handleZoomIn = () => {
        props.onZoomIn();
    }

    return <ul className="controls">
        <li>
            <img src={img360} alt="helloar" />
        </li>
        {/* <li>
            <button onClick={handleFullScreen}>{props.isFullScreen ? <img src={exitFullscreen} alt="helloar" /> : <img src={fullscreen} alt="helloar" />}</button>
        </li> */}
        {
            !props.hideZoom ? (<li>
                <button onClick={handleZoomOut} disabled={props.scale === 1}>{<img src={zoomOut} alt="helloar" />}</button>
            </li>) : ("")
        }
        {
            !props.hideZoom ? (<li>
                <button onClick={handleZoomIn} disabled={props.scale >= 4}>{<img src={zoomIn} alt="helloar" />}</button>
            </li>) : ("")
        }

    </ul>
}