import { IMapping } from "../models/Mapping";

export class MappingData {
    mapping: IMapping[];

    constructor(data: IMapping[]) {
        this.mapping = data;
    }

    getTabs(): string[] {
        return this.mapping?.map((item) => item.display_name);
    }

    getTabData(tab: string) {
        return this.mapping?.filter((item) => item.display_name === tab)[0]
    }
}