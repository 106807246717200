import { SubTab } from "../models/Mapping";
import { IAudioItem, IMultipleSlots, IProduct, ISlotImage, ISphericalImage, ISpinImage, IVideoItem } from "../models/Product";

export class ProductData {
    product: IProduct;

    constructor(data: IProduct) {
        this.product = data;
    }


    getSpinData(slotNames: string[]): ISpinImage | null {
        if(this.product.spin_images) {
            let imageUrl = null;
            slotNames.forEach((slotName) => {
                this.product?.spin_images.forEach((item, index) => {
                    if (item.name === slotName ) {
                        imageUrl = item;
                        imageUrl.spin_index = index;

                    }
                    else if (item.smart_spin) {
                        imageUrl = this.product.slot_images.find((slot) => slot.name === item.smart_spin?.slot_name);
                    }

                })
            });
            return imageUrl;
        }
        else{
            return null;
        }
    }

    getSmartSpinData(): ISlotImage | null {
        if(this.product.spin_images) {
            let imageUrl = null;
                this.product?.spin_images.forEach((item, index) => {
                    if (item.smart_spin) {
                        imageUrl = this.product.slot_images.find((slot) => slot.name === item.smart_spin?.slot_name);
                    }

                })
            return imageUrl;
        }
        else{
            return null;
        }
    }

    getSphericalData(slotNames: string[]): ISphericalImage | null {
        if(this.product.spherical_images) {
        let imageUrl = null;
        slotNames.forEach((slotName) => {
            this.product?.spherical_images.forEach((item) => {
                if (item.name === slotName) {
                    imageUrl = item
                }
            })
        });
        return imageUrl;
    }
    else{
        return null;
    }
    }

    getGalleryData(): ISlotImage[] {
        return this.product.slot_images;
    }

    getVideoData(): IVideoItem[] {
        return this.product.video_items;
    }

    getAudioData(): IAudioItem[] {
        return this.product.audio_items;
    }


    getSubTabs(subTabs: SubTab[]) {
        //return tabs which have images present
        let newSubTab: SubTab[] = [];
        subTabs.forEach((item) => {
            this.product.slot_images.forEach((slot) => {
                let index = item.slot_names.indexOf(slot.name);
                let existingTabsIndex = newSubTab.indexOf(item)
                if (index > -1 && slot.images.length > 0 && existingTabsIndex === -1) {
                    newSubTab.push(item);
                }
            })
            if (item.type==="Video"||item.type==="Audio") {
                newSubTab.push(item);
            }
        });
        return newSubTab;
    }

    getId() {
        return this.product._id;
    }
}