import React, { useEffect, useState } from "react";
import Controls from "../shared/components/Controls";
import { getLowQualityImg } from "../shared/helper/image";
import { SubTab } from "../shared/models/Mapping";
import { ISlotImage, IImage, IVideo, IVideoItem, IAudioItem, IAudio } from "../shared/models/Product";
import videoImg from '../assets/video50.png'
import audioImg from '../assets/soundWave.png'
import Audio from "./Audio";

type Props = {
    slotImages: ISlotImage[];
    subTabs: SubTab[];
    videos: IVideoItem[];
    audios : IAudioItem[]
}

export default function Gallery(props: Props) {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [scale, setScale] = useState(1);
    const [subCategoryImgs, setSubCategoryImgs] = useState<IImage[]>([]);
    const [subCategoryVideos, setSubCategoryVideos] = useState<IVideo[]>([])
    const [subCategoryAudios, setSubCategoryAudios] = useState<IAudio[]>([])
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [currentSlide, setCurrentSlide] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [audioPresent, setAudioPresent] = useState<boolean>(false);
    const [videoSubTabPresent, setVideoSubTabPresent] = useState<boolean>(false);
    const [audioSubTabPresent, setAudioSubTabPresent] = useState<boolean>(false);
    const [sidebarItems, setSidebarItems] = useState<string[]>([]);

    useEffect(() => {
        //set default slider images
        let galleryImg: IImage[] = props.slotImages[0].images.filter((item) => item.image_url);
        setSubCategoryImgs(galleryImg);

        if (props.videos !== undefined && props.videos.length>0){
            let galleryVid: IVideo[] = props.videos[0].videos.filter((item)=>item.video_url)
            setSubCategoryVideos(galleryVid)
        }

        if (props.audios !== undefined && props.audios.length>0){
            let galleryAud: IAudio[] = props.audios[0].audios.filter((item)=>item.audio_url)
            setSubCategoryAudios(galleryAud)
        }
    }, [props.slotImages,props.videos])

    useEffect(() => {
        //set default selected category
        setSelectedCategory(props.subTabs ? props.subTabs[0].display_name : "");
        if(props.subTabs){
            props.subTabs.map((item) => {
                if (item.display_name === "VIDEOS") {
                    setVideoSubTabPresent(true)
                }
                if (item.display_name === "AUDIOS") {
                    setAudioSubTabPresent(true)
                }
                if(item.display_name === "Media"){
                    setSidebarItems(item.slot_names)
                    handleCategorySelection(item)

            //get image list for sub category using slot names
            let imageList: IImage[] = [];
            item.slot_names.forEach((slot) => {
                props.slotImages.forEach((item) => {
                    if (item.name === slot) {
                        imageList = [...imageList, ...item.images]
                    }
                });
            });
    
            //get images where url is not empty
            let notEmptyImages = imageList.filter(item => item.image_url)
            setSubCategoryImgs(notEmptyImages);
                }
            })
        }
    }, [props.subTabs])

    const onFullScreen = () => {
        setIsFullScreen(!isFullScreen);
        //TODO:other browsers
        if (!isFullScreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }

    const onZoomIn = () => {
        setScale(scale + 0.1)
    }

    const onZoomOut = () => {
        setScale(scale - 0.1)
    }

    const handleCategorySelection = (subTab: SubTab) => (event: React.MouseEvent<HTMLElement>) => {
        //set selected dropdown option
        if (subTab.display_name === "AUDIOS" ){
            setAudioPresent(!audioPresent)
        }
        else{

            setSelectedCategory(subTab.display_name);

            //reset selected slider image
            setCurrentSlide(0);
    
            //reset zoom value
            setScale(1);
    
            //get image list for sub category using slot names
            let imageList: IImage[] = [];
            subTab.slot_names.forEach((slot) => {
                props.slotImages.forEach((item) => {
                    if (item.name === slot) {
                        imageList = [...imageList, ...item.images]
                    }
                });
            });
    
            //get images where url is not empty
            let notEmptyImages = imageList.filter(item => item.image_url)
            setSubCategoryImgs(notEmptyImages);
        }
    }

    const handleToggle = (e:any) => {
        e.stopPropagation();
        e.preventDefault();
        setDropdownOpen(!dropdownOpen);

    }

    const handleCurrentSlide = (index: number) => () => {
        setCurrentSlide(index);
    }

    const handleScroll = (event: React.WheelEvent<HTMLElement>) => {
        if (event.deltaY < 0 && scale <= 4) {
            onZoomIn();
        }
        else if (event.deltaY > 0 && scale !== 1) {
            onZoomOut();
        }
    }

    return <div className="viewer gallery">
        <div>
            <div className={ props.subTabs.length > 1 ? `dropdown ${dropdownOpen ? "active" : ""}` : `dropdown inactive`} onClick={handleToggle}>
                {<input type="text" placeholder="Select on" readOnly={true} value={selectedCategory} />}
                {
                    props.subTabs.length > 1 && 
                    <div className="options">
                        {
                            props.subTabs.map((item) => (
                                <div onClick={handleCategorySelection(item)} key={item.display_name}>
                                    {item.display_name}
                                </div>
                            ))
                        }
                    </div>
                }
                <div className="audio">
                    {audioPresent && <Audio audios={props.audios}/>}
                </div>
            </div>
        </div>
        <div className="imgContainer">
                {
                    subCategoryImgs.map((item, index) => {
                        if (item.image_url) {
                            return <div
                            style={{
                                display: currentSlide === index ? "block" : "none",
                                transform: `scale(${scale}) translate(0px, 0px)`
                            }}
                            >
                                <img key={item.name}
                                style={{
                                    display: currentSlide ===  index  ? "block" : "none",
                                    transform: `scale(${scale}) translate(0px, 0px)`
                                }}
                                src={getLowQualityImg(item.image_url) }
                                alt="helloar"
                                onWheelCapture={handleScroll} />
                                    <div className="imgNameOverlay">
                                        <h3>{item.name}</h3>
                                    </div>
                                </div>
                        }
                    })
                }
                { sidebarItems.includes("Videos") &&
                    subCategoryVideos.map((item, index) => {
                        if (item.video_url) {
                            return (
                                <div
                            style={{
                                display: currentSlide === subCategoryImgs.length+index ? "block" : "none",
                                transform: `scale(${scale}) translate(0px, 0px)`,
                            }}>
                                <video  className="Video" controls key={item.video_name}
                                style={{
                                    display: currentSlide === subCategoryImgs.length+index ? "block" : "none",
                                    transform: `scale(${scale}) translate(0px, 0px)`,
                                }}
                                >
                                    <source src={item.video_url} type="video/mp4"/>
                                </video>

                                    <div className="imgNameOverlay">
                                        <h3>{item.video_name}</h3>
                                    </div>
                            </div>
                                )
                        } 
                    })
                }
                {   sidebarItems.includes("Audios") &&
                    subCategoryAudios.map((item, index) => {
                        if (item.audio_url) {
                            return (
                            <div
                                style={{
                                    display: currentSlide === subCategoryImgs.length+ subCategoryVideos.length+ index ? "block" : "none",
                                    transform: `scale(${scale}) translate(0px, 0px)`,
                                }}>
                                <div className="modal" id="audioPlayer" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-header">
                                            <h3 className="modal-title" id="exampleModalLabel">{item?.audio_name}</h3>
                                        </div>
                                        <div className="modal-body">
                                            <audio controls>
                                                <source src={item?.audio_url} type="audio/mpeg" />
                                            </audio>
                                        </div>
                                    </div>
                                </div>
                                <div className="imgNameOverlay">
                                    <h3>{item.audio_name}</h3>
                                </div>
                        </div>)
                        } 
                        else{
                            return ""
                        }
                    })
                }
        </div>
        <div className="thumbnail">
                {
                    subCategoryImgs.map((item, index) => {
                        if (item.image_url) {
                            return <img key={item.name} className={currentSlide === index ? "active" : ""} src={getLowQualityImg(item.image_url)} alt="helloar" onClick={handleCurrentSlide(index)} />
                        } else {
                            return ""
                        }
                    })
                }
                {   sidebarItems.includes("Videos") &&
                    subCategoryVideos.map((item, index) => {
                        if (item.video_url) {
                            return (
                                <div  className={currentSlide === subCategoryImgs.length+index ? "audioThumb active" : "audioThumb"}>
                                    <img key={item.video_name} src={getLowQualityImg(videoImg)} alt="helloar" onClick={handleCurrentSlide(subCategoryImgs.length+index)} 
                                    // style={{ width:"60%",padding:"10px"}} 
                                    />
                                </div>
                                )
                        } else {
                            return ""
                        }
                    })
                }
                {   sidebarItems.includes("Audios") &&
                     subCategoryAudios.map((item, index) => {
                        if (item.audio_url) {
                            return (
                                <div  className={currentSlide === subCategoryImgs.length+subCategoryVideos.length+index ? "videoThumb active" : "videoThumb"}>
                                    <img key={item.audio_name} src={getLowQualityImg(audioImg)} alt="helloar" onClick={handleCurrentSlide(subCategoryImgs.length+subCategoryVideos.length+index)} 
                                    // style={{ width:"60%", padding:"10px"}} 
                                    />
                                </div>
                                )
                        } else {
                            return ""
                        }
                    })
                }

        </div> 
            {
                selectedCategory !== "VIDEOS" && (
                    <Controls hideZoom={false} onFullScreen={onFullScreen} onZoomIn={onZoomIn} onZoomOut={onZoomOut} isFullScreen={isFullScreen} scale={scale} />
                )
            }
        </div>
}