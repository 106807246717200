import { useEffect, useRef, useState } from "react";
import {
    SphericalService
} from "helloar/build/helloar.min.js";
import Controls from "../shared/components/Controls";
import { ISphericalImage } from "../shared/models/Product";
import HotspotUI from "../shared/components/HotspotUI";
import { getLowQualityImg } from "../shared/helper/image";
import { Loader } from "../shared/components/Loader";


type Props = {
    image: ISphericalImage | null
}

export default function SphericalViewer(props: Props) {
    const viewerRef: any = useRef(null);
    const interiorDivRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [scale, setScale] = useState(1);
    const [showHotspotUI, setShowHotspotUI] = useState(false);
    const [hotspotData] = useState<any>(null);
    const [loadingProgress, setLoadingProgress] = useState(0);

    useEffect(() => {
        if (props.image) {
            let lat = null;
            let lon = null;
            let fov = null;
            let minFov = 0;

            let autoPlay = true;
            let autoPlaySpeed = 0.1;

            viewerRef.current = new SphericalService(
                interiorDivRef.current,
                getLowQualityImg(props.image.image_url),
                autoPlay,
                lat,
                lon,
                fov,
                autoPlaySpeed,
                onProgress,
                (fov: any) => onZoom(viewerRef.current.cameraDefaultFOV / fov),
                onDoubleClick,
                minFov
            );
            viewerRef.current.init();
            //viewerRef.current.initHotspots(0);
            //viewerRef.current.hotspotManager.setCustomInteractionFunction(hotspotClicked);
        }
        return () => {
            //on unmount destroy the SpinnerImage360 class instance
            if (viewerRef.current) {
                viewerRef.current.destroy();
            }
        };

    }, [props.image]);

    const onProgress = (progress: any) => {
        setLoadingProgress(progress);
    };

    const onFullScreen = () => {
        setIsFullScreen(!isFullScreen);
        //TODO:other browsers
        if (!isFullScreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }

    const onZoom = (zoomLevel: number) => {
        console.log(`zoom: ${zoomLevel}`);
    };

    const onDoubleClick = () => {
        console.log("double clickedd");
    };

    const onZoomIn = () => {
        viewerRef.current.zoom(2500);
        setScale(viewerRef.current.scale);
    }

    const onZoomOut = () => {
        viewerRef.current.zoom(-0.1);
        setScale(viewerRef.current.scale);
    }

    // const hotspotClicked = (allHotspots: any, index: number) => {
    //     setHotspotData(allHotspots[index]);
    //     setShowHotspotUI(true);
    // };

    const handleHotSpotDisplay = () => {
        setShowHotspotUI(!showHotspotUI);
    }

    useEffect(() => {
        if (viewerRef.current && viewerRef.current.initialized) {
            viewerRef.current.refresh()
        }
    }, [isFullScreen]);

    return interiorDivRef ? (
        <div className="viewer">
            {
                loadingProgress !== 100 && (<Loader loadingProgress={loadingProgress} />)
            }
            {showHotspotUI && hotspotData && (<HotspotUI data={hotspotData} hotSpotDisplayAction={handleHotSpotDisplay} />)}
            <div className="imgContainer">
                <div ref={interiorDivRef}></div>
            </div>
            <Controls hideZoom={true} onFullScreen={onFullScreen} onZoomIn={onZoomIn} onZoomOut={onZoomOut} isFullScreen={isFullScreen} scale={scale} />
        </div>
    ) : (
        <div className="viewer">
            <div className="imgContainer">
                no image found
            </div>
            <Controls hideZoom={true} onFullScreen={onFullScreen} onZoomIn={onZoomIn} onZoomOut={onZoomOut} isFullScreen={isFullScreen} scale={scale} />
        </div>
    );
}