export function getLowQualityImg(url: string, quality: number = 1920) {
  // const allowedQualities = [1080, 1920, 4096];
  // let urlWithQuality = url;
  // if (url.includes("uploads") && allowedQualities.includes(quality)) {
  //   urlWithQuality = url.replace("uploads", quality.toString());
  // }
  // return urlWithQuality;
  console.log(quality);
  return url;
}
