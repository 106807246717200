import { useEffect, useMemo, useRef, useState } from "react";
import { Spinner360ImageViewer } from "helloar/build/helloar.min.js";
import Controls from "../shared/components/Controls";
import { ISlotImage, ISpinImage } from "../shared/models/Product";
import HotspotUI from "../shared/components/HotspotUI";
import { getLowQualityImg } from "../shared/helper/image";
import { Loader } from "../shared/components/Loader";

type Props = {
  imageSmart: ISlotImage | null;
  image: ISpinImage | null;
  autoPlay: boolean | undefined;
  viewerImageEndBug: boolean | undefined;
  _id: string;
};

export default function SpinViewer(props: Props) {
  const viewerRef: any = useRef(null);
  const spinDivRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [scale, setScale] = useState(1);
  const [showHotspotUI, setShowHotspotUI] = useState(false);
  const [hotspotData, setHotspotData] = useState<any>(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const spinIndex =
    typeof props.image?.spin_index === "number" ? props.image?.spin_index : 1;

  useEffect(() => {
    viewerRef.current = new Spinner360ImageViewer(
      spinDivRef.current,
      undefined,
      undefined,
      "contain",
      onLoadingProgress,
      onZoom
    );
    viewerRef.current.initHotspots(spinIndex);
    viewerRef.current.hotspotManager.setCustomInteractionFunction(
      hotspotClicked
    );

    return () => {
      //on unmount destroy the SpinnerImage360 class instance
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
    };
  }, [spinIndex]);

  useEffect(() => {
    if (viewerRef.current && viewerRef.current.initialized) {
      viewerRef.current.destroy();
      viewerRef.current.init();
    } else if (viewerRef.current) {
      //if Spinner360Image class constructor has been called but not been initialized
      viewerRef.current.init();
    }
  }, [props.image]);

  const onFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    //TODO:other browsers
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const onZoomIn = () => {
    viewerRef.current.zoom(1);
  };

  const onZoomOut = () => {
    viewerRef.current.zoom(-1);
  };

  const onZoom = (zoomLevel: number) => {
    setScale(zoomLevel);
  };

  const hotspotClicked = (allHotspots: any, index: number) => {
    setHotspotData(allHotspots[index]);
    setShowHotspotUI(true);
  };

  const onLoadingProgress = (progress: number) => {
    setLoadingProgress(progress);
  };

  const handleHotSpotDisplay = () => {
    setShowHotspotUI(!showHotspotUI);
  };

  const imageFolderUrl = useMemo(() => {
    return props.image?.image_folder_url
      ? getLowQualityImg(props.image.image_folder_url)
      : "";
  }, [props]);

  const imagesList = useMemo(() => {
    const list: string[] = [];
    if (props.imageSmart) {
      props.imageSmart.images.forEach((image: any) => {
        if (image && image.image_url && image.image_url !== "") {
          list.push(image.image_url);
        }
      });
    }
    return list;
  }, [props]);

  return (
    <div className="viewer" style={{ display: "flex" }}>
      {loadingProgress !== 100 && <Loader loadingProgress={loadingProgress} />}
      {showHotspotUI && hotspotData && (
        <HotspotUI
          data={hotspotData}
          hotSpotDisplayAction={handleHotSpotDisplay}
        />
      )}
      <div className="imgContainer">
        {props.image ? (
          <div
            ref={spinDivRef}
            data-folder={imageFolderUrl}
            data-image-list={imagesList && imagesList.length > 0 ? JSON.stringify(imagesList) : undefined}
            data-amount={props.image?.image_count ?? imagesList.length}
            data-autoplay={props.autoPlay}
            data-startAt={props.image.start_at}
            data-endAt={props.image.end_at}
            data-viewer-end-image-bug={props.viewerImageEndBug}
          ></div>
        ) : (
          <div>
            <h1>No Image Found</h1>
          </div>
        )}
      </div>
      <Controls
        hideZoom={false}
        onFullScreen={onFullScreen}
        onZoomIn={onZoomIn}
        onZoomOut={onZoomOut}
        isFullScreen={isFullScreen}
        scale={scale}
      />
    </div>
  );
}
