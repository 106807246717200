import React, { useState } from 'react'
import { IAudio, IAudioItem} from '../shared/models/Product';

type Props = {
    audios: IAudioItem[];
}

function Audio(props: Props) {
    const [selectedCategory, setSelectedCategory] = useState<IAudio>();
    const [audioDropdownOpen, setAudioDropdownOpen] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const subTabs:IAudio[] = props.audios[0].audios;

    const handleCategorySelection = (subTab:IAudio) => (event: React.MouseEvent<HTMLElement>) => {
        //set selected dropdown option
        setSelectedCategory(subTab);
        // to set modal to show
        setModalShow(true);
    }

    const handleToggleAudio = (e:any) => {
        e.stopPropagation();
        e.preventDefault();
        setAudioDropdownOpen(!audioDropdownOpen);

    }

    return (
        <div className="">
            <div className={`audioDropdown ${audioDropdownOpen ? "active" : ""}`} onClick={handleToggleAudio}>
                <input type="text" placeholder="Audios" readOnly={true} value={selectedCategory?.audio_name} />
                <div className="AudioOptions">
                    {
                        subTabs.map((item) => (
                            <div onClick={handleCategorySelection(item)} key={item.audio_name}>
                                {item.audio_name}
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                selectedCategory?.audio_url && modalShow === true && (
                    <div className="modal" id="audioPlayer" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                        <div className="modal-dialog" role="document">
                            <div className="modal-header">
                                <h3 className="modal-title" id="exampleModalLabel">{selectedCategory?.audio_name}</h3>
                                <button className="modal-button" type="button"  aria-label="Close" onClick={()=>{setModalShow(false); }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <audio controls>
                                    <source src={selectedCategory?.audio_url} type="audio/mpeg" />
                                </audio>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Audio