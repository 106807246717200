import { IApp } from "../shared/models/App";

export const findSubscriptionStatus = (application: IApp) => {
  if (
    application?.plan_subscription?.start_date &&
    application?.plan_subscription?.end_date
  ) {
    const today = new Date();
    const planEndDate = new Date(application.plan_subscription.end_date);
    if (planEndDate.getTime() <= today.getTime()) {
      return false;
    }
  }
  return true;
};
