import React, { useState } from "react";

type Props = {
    tabs: string[];
    setTabSelected: (id: string) => void;
    selectedTab: string;
}

export default function Tabs(props: Props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    //On click of tab selection
    const tabSelectionEvent = (event: React.ChangeEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
        let id = event.currentTarget.id;
        props.setTabSelected(id)
    }

    //dropdown menu in mobile
    const handleToggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    let style = { "--i": props.selectedTab, "--length": props.tabs.length } as React.CSSProperties;
    return <div className="tabs">
        <ul style={style} className={`hide-mobile`}>
            {
                props.tabs.map((item, index) => (
                    <li key={item} className={props.selectedTab === item ? "active" : ""}>
                        <input type="radio" name="radioTab" id={item} value={item} checked={props.selectedTab === item} onChange={tabSelectionEvent} />
                        <label htmlFor={`radio${index}`} data-i={index}>{item}</label>
                    </li>
                ))
            }
        </ul>
        <div className={`hideDesktop dropdown ${dropdownOpen ? "active" : ""}`} onClick={handleToggle}>
            <input type="text" placeholder="Select on" readOnly={true} value={props.selectedTab} />
            <div className="options">
                {
                    props.tabs.map((item, index) => (
                        <div id={item} onClick={tabSelectionEvent} key={item}>{item}</div>
                    ))
                }
            </div>
        </div>
    </div >
}