type Props = {
    loadingProgress: number;
}

export function Loader(props: Props) {
    return <div className="loader">
        <p>{"Loading  360° tour..."}</p>
        <div className="container">
            <div className="progress" style={{ width: `${(props.loadingProgress * 160) / 100}px` }}></div>
        </div>
    </div>
}